@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

/* Réinitialisation des styles par défaut */
html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: linear-gradient(160deg, #171717, #2F2F2F);
}

/* Styles de base pour le document */
body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #000;
  overflow: hidden; /* Désactive la barre de défilement de la page complète */
}

/* Styles responsives pour la police */
@media (max-width: 768px) {
  body {
    font-size: 14px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  body {
    font-size: 16px;
  }
}

@media (min-width: 1025px) {
  body {
    font-size: 18px;
  }
}

.fade-enter {
  opacity: 0;
}

.container {
  padding-top: 60px;
}

.header {
  position: fixed;
  top: 0;

  /* width: 100%; */
  z-index: 999;
}

.content {
  /* max-height: 80%; */
}
